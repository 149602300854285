import {Component, Input, OnInit} from '@angular/core';
interface PlayerData {
  players: any[]
}
@Component({
  selector: 'app-players',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.css']
})
export class PlayersComponent implements OnInit {
  @Input() currentGameData: PlayerData
  constructor() { }

  ngOnInit(): void {
  }

}
