import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
interface RespData {
  text: string
}
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  step = 'phone'
  phoneForm = this.form.group({
    phone: [null, Validators.maxLength(11)],
  });
  codeForm = this.form.group({
    code: [null, Validators.required]
  })
  constructor(private form: FormBuilder, private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
  }
  showNotification(from, align, text){
    const type = ['', 'info', 'success', 'warning', 'danger'];
    $.notify({
      icon: "pe-7s-shield",
      message: text
    },{
      type: 'danger',
      timer: 1000,
      placement: {
        from: from,
        align: align
      }
    });
  }
  submitForm() {
    if (this.step === 'phone') {
      this.auth.submitLoginData({...this.phoneForm.value}).subscribe(e => {
        this.step = 'code'
      }, error => {
        this.showNotification('top', 'left', error.error)
      })
    }
    if (this.step === 'code') {
      this.auth.submitLoginData({...this.phoneForm.value, ...this.codeForm.value}).subscribe(data => {
        const response = data as RespData;
        localStorage.setItem('id_token', response.text)
        this.router.navigate(['/'])
      })
    }
  }

}
