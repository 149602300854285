import {Component, Input, OnInit} from '@angular/core';
import {Part} from "../../../../shared/interfaces/game";
import {FormBuilder, Validators} from "@angular/forms";
import {GameService} from "../../../../services/game.service";

@Component({
  selector: 'app-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.scss']
})
export class StageComponent implements OnInit {
  @Input() stage: Part
  titleEdit = false;
  descriptionEdit = false;
  stageForm = this.fb.group({
    name: [null, Validators.required],
    description: [null, Validators.required]
  })
  constructor(private fb: FormBuilder, private uploader: GameService) { }
  onFileSelected(event, id, photoId) {
    this.uploadPhoto(event.target.files[0], id, photoId)
  }
  switchTitle() {
    if(this.titleEdit) {
      this.uploader.dataObserver.next({type: 'title', id: this.stage.id, name: this.stageForm.controls['name'].value})
    }
    this.titleEdit = !this.titleEdit
  }
  switchDescription() {
    if(this.descriptionEdit) {
      this.uploader.dataObserver.next({type: 'description', id: this.stage.id, description: this.stageForm.controls['description'].value})
    }
    this.descriptionEdit = !this.descriptionEdit
  }
  uploadPhoto(data: File, id, photoId) {
    const fp = new FormData()
    fp.append('uploadedFiles', data, data.name)
    this.uploader.uploadPhoto(fp).subscribe(e => {
      this.uploader.photoObserver.next({
        id: id,
        fileId: photoId,
        fileUpload: e
      })
    })
    console.log(id)
  }
  ngOnInit() {
    this.stageForm.controls['name'].setValue(this.stage.name)
    this.stageForm.controls['description'].setValue(this.stage.description)
  }

}
