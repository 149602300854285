import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_BASE_URL} from './auth.service';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GameService {

  photoObserver: Subject<object> = new Subject<object>();
  dataObserver: Subject<object> = new Subject<object>();

  constructor(private http: HttpClient) { }

  getAllGames() {
    return this.http.get(`${API_BASE_URL}api/games/0`)
  }
  getCurrentGame(id) {
    return this.http.get(`${API_BASE_URL}api/games/${id}`)
  }
  updateCurrentGame(data) {
    return this.http.patch(`${API_BASE_URL}api/games/update-admin`, data)
  }
  getTopPlayers() {
    return this.http.get(`${API_BASE_URL}api/rates`)
  }
  uploadPhoto(data) {
    return this.http.post(`${API_BASE_URL}api/appFiles`, data)
  }
}
