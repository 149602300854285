import { Component, OnInit } from '@angular/core';
import {GameService} from '../services/game.service';
import {Router} from "@angular/router";
interface GameConfig {
  id: number,
  name: string,
  balance: number,
  players: []
}
@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.css']
})
export class GamesComponent implements OnInit {
  tableKeys = ['ID', 'Название', 'Баланс', 'Игроки', 'Управление']
  tableData1: any;
  loadedTable = false;
  constructor(private game: GameService, private router: Router) { }

  editGame(data) {
    this.router.navigate(['/games/' + data])
    console.log(data)
  }
  ngOnInit() {
    this.game.getAllGames().subscribe(data => {
      const dataTable = data as []
      let emptyArr = []
      const parsedData = dataTable.forEach(e => {
        let cellArr = []
        const game = e as GameConfig
        cellArr.push(game.id)
        cellArr.push(game.name)
        cellArr.push(game.balance)
        cellArr.push(game.players.length)
        emptyArr.push(cellArr)
      })
      this.tableData1 = emptyArr
      this.loadedTable = true;
    })
  }

}
