import { Component, OnInit } from '@angular/core';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import {AuthService} from './services/auth.service';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    isLogin: any;
     constructor(private auth: AuthService, private router: Router) { }

    ngOnInit() {
         this.router.events.subscribe(e => {
             if (e instanceof NavigationEnd) {
                 this.isLogin = this.auth.isAuth();
                 if(!this.isLogin) {
                     this.router.navigate(['/login'])
                 }
             }
         })
         this.isLogin = this.auth.isAuth();
         if (!this.isLogin) {
             this.router.navigate(['/login'])
         }
    }
}
