import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {GameService} from "../../services/game.service";
import {FormBuilder, Validators} from "@angular/forms";
import {NotificationService} from "../../services/notification.service";
import {GameRoot} from "../../shared/interfaces/game";
import * as _ from 'lodash';

interface ResponseData {
  isCompleted: boolean,
  isDelete: boolean,
  isJoin: boolean,
  isPassword: boolean,
  isPublish: boolean,
  isVerified: boolean,
  money: number,
  name: string,
  balance: number,
  user: any,
  players: []
}
@Component({
  selector: 'app-current-game',
  templateUrl: './current-game.component.html',
  styleUrls: ['./current-game.component.css']
})
export class CurrentGameComponent implements OnInit {
  currentId = this.router.url.split('/')[2]
  currentGameData: GameRoot;
  errorBoundry = false;
  erroTitle: string;
  gameForm = this.fb.group({
    isCompleted: [false, Validators.required],
    isDelete: [false, Validators.required],
    isJoin: [false, Validators.required],
    isPassword: [false, Validators.required],
    isPublish: [false, Validators.required],
    isVerified: [false, Validators.required],
    money: [null, Validators.required],
    name: [null, Validators.required],
    balance: [null, Validators.required]
  })
  constructor(private fb: FormBuilder,
              private game: GameService,
              private router: Router,
              private notify: NotificationService) { }
  onSubmit() {
    this.game.updateCurrentGame({...this.currentGameData, ...this.gameForm.value}).subscribe(e => {
      this.notify.showNotification('top', 'left', 'Успешно!', 'success')
    })
  }
  parseData() {
    this.game.getCurrentGame(this.currentId).subscribe(data => {
      this.currentGameData = data[0] as GameRoot;
      let fields = ['isCompleted', 'isDelete', 'isPassword', 'isPublish', 'isVerified', 'money', 'name', 'balance']
      fields.forEach(e => {
        this.gameForm.controls[e].setValue(this.currentGameData[e])
      })
    }, error => {
      this.errorBoundry = true;
      this.erroTitle = error.error
    })
  }
  ngOnInit() {
    this.game.dataObserver.subscribe(e => {
      // @ts-ignore
      let type = e.type;
      switch (type) {
        case 'title': {
          // @ts-ignore
          this.currentGameData.parts[_.findIndex(this.currentGameData.parts, {id: e.id})].name = e.name
          break;
        }
        case 'description': {
          // @ts-ignore
          this.currentGameData.parts[_.findIndex(this.currentGameData.parts, {id: e.id})].description = e.description
          break;
        }
      }
      this.onSubmit();
    })
    this.game.photoObserver.subscribe(e => {
      console.log(e)
      // e.id = ID PART
      // e.fileId = ID FILE
      // @ts-ignore
      let part = this.currentGameData.parts[_.findIndex(this.currentGameData.parts, {id: e.id})]
      // @ts-ignore
      this.currentGameData.parts[_.findIndex(this.currentGameData.parts, {id: e.id})].files[_.findIndex(part.files, {id: e.fileId})].file = e.fileUpload[0]
      // @ts-ignore
      this.currentGameData.parts[_.findIndex(this.currentGameData.parts, {id: e.id})].files[_.findIndex(part.files, {id: e.fileId})].fileId = e.fileUpload[0].id
      // @ts-ignore
      this.onSubmit();
    })
    this.parseData()
  }

}
