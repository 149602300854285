import { Injectable } from '@angular/core';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() { }

  showNotification(from, align, text, status){
    const type = ['', 'info', 'success', 'warning', 'danger'];
    $.notify({
      icon: "pe-7s-shield",
      message: text
    },{
      type: status,
      timer: 1000,
      placement: {
        from: from,
        align: align
      }
    });
  }
}
