import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
 export const API_BASE_URL = 'https://findme.3dcafe.ru/'
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }
  submitLoginData(data) {
    return this.http.post(`${API_BASE_URL}auth/login`, data)
  }
  isAuth() {
    if (localStorage.id_token){
      return true;
    } else {
      return false;
    }
  }
}
