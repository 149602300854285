import {Component, Input, OnInit} from '@angular/core';
import {GameRoot, Part} from "../../../shared/interfaces/game";

@Component({
  selector: 'app-stages',
  templateUrl: './stages.component.html',
  styleUrls: ['./stages.component.scss']
})
export class StagesComponent implements OnInit {
  @Input() stagesData: Part[];
  constructor() { }

  ngOnInit(): void {
  }

}
